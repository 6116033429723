import { createRouter, createWebHashHistory } from 'vue-router';

/* Layout */
import Layout from '@/layout';

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index'),
            },
        ],
    },
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true,
    },
    {
        path: '/auth-redirect',
        component: () => import('@/views/login/auth-redirect'),
        hidden: true,
    },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true,
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true,
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [
            {
                path: 'dashboard',
                component: () => import('@/views/dashboard/index'),
                redirect: '/user/list',

                name: 'Dashboard',
                meta: { title: '主页' },
            },
            {
                path: '/system/admin-user',
                component: () => import('@/views/system/AdminUser'),
                name: 'AdminUser',
                meta: { title: '管理员管理' },
            },
            {
                path: '/system/edit/:id',
                component: () => import('@/views/system/AdminUserCreate'),
                name: 'AdminUserCreate',
                meta: { title: '管理员编辑' },
            },
            {
                path: '/system/config-list',
                component: () => import('@/views/system/ConfigList'),
                name: 'ConfigList',
                meta: { title: '配置管理' },
            },
            {
                path: '/system/tokenAlert',
                component: () => import('@/views/system/TokenAlert'),
                name: 'TokenAlert',
                meta: { title: '提醒点' },
            },
            {
                path: '/system/alertLog',
                component: () => import('@/views/system/AlertLog'),
                name: 'AlertLog',
                meta: { title: '提醒日志' },
            },
            {
                path: '/system/push',
                component: () => import('@/views/system/Push'),
                name: 'SystemPush',
                meta: { title: '分析师推送' },
            },

            {
                path: '/strategy/list',
                component: () => import('@/views/system/StrategyList'),
                name: 'StrategyList',
                meta: { title: '推送策略' },
            },
            // {
            //     path: '/strategy/save',
            //     component: () => import('@/views/system/StrategySave'),
            //     name: 'StrategySave',
            //     meta: { title: '推送策略保存' },
            // },
            {
                path: '/user/list',
                component: () => import('@/views/user/UserList'),
                name: 'UserList',
                meta: { title: '用户管理' },
            },
            
            {
                path: '/subscribe/list',
                component: () => import('@/views/user/SubscribeList'),
                name: 'SubscribeList',
                meta: { title: '会员管理' },
            },
            {
                path: '/push/config',
                component: () => import('@/views/system/PushConfig'),
                name: 'PushConfig',
                meta: { title: '推送配置' },
            },
            {
                path: '/icon/config',
                component: () => import('@/views/system/IconConfig'),
                name: 'IconConfig',
                meta: { title: '币种配置' },
            },

            
            {
                path: '/user/invite',
                component: () => import('@/views/user/UserInvite'),
                name: 'UserInvite',
                meta: { title: '邀请列表' },
            },
            {
                path: '/user/user-edit/:id',
                component: () => import('@/views/user/UserCreate'),
                name: 'UserCreate',
                meta: { title: '用户编辑' },
            },
            {
                path: '/user/charginglog',
                component: () => import('@/views/user/ChargingLog'),
                name: 'ChargingLog',
                meta: { title: '充值记录' },
            },
            {
                path: '/system/email-log',
                component: () => import('@/views/system/EmailLog'),
                name: 'EmailLog',
                meta: { title: '邮件记录' },
            },
        ],
    },
];

const router = createRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
    history: createWebHashHistory(),
});

export default router;
